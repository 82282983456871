import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

const HowtoPage = () => (
  <div id="indexPage">
    <Header/>
    <div className="content-pad">
      <h2>Standard Packages</h2>
      <p>
	FISH MED NET est un projet qui soutient la diversification de l&#39;activité principale des pêcheurs. Il vise à permettre aux pêcheurs et aux coopératives de pêche d'explorer différentes voies afin d'augmenter les services dans le but d&#39;améliorer leurs moyens de subsistance, l'économie locale et l&#39;environnement environnant. L'une de ces activités consiste à promouvoir l'utilisation de leurs installations pour des services communautaires, où des relations formalisées entre des entités privées et publiques peuvent avoir lieu.
      </p>
	<p>
	C'est précisément pour cela que FISH MED NET a développé 4 différents types d'accords formels, connus sous le nom de packages standard, qui permettront de démarrer un processus formel de comparaison entre les pêcheurs et les sujets publics-privés.
	</p>
	<p>
	Vous trouverez ci-dessous les liens pour les quatre différents types de packages standard. Ces documents peuvent être utilisés librement comme modèles et librement modifiés. Nous espérons que ces documents fourniront l'aide nécessaire pour démarrer une coopération réussie et fructueuse entre tous les utilisateurs. L'utilisation de ces documents est uniquement au risque des utilisateurs finaux et le projet IEV CTF MED, les partenaires et les organisations ne peuvent être tenus responsables de toute divergence et/ou action développée à partir de l'un des
accords formalisés ou non formalisés conclus et menés. par les utilisateurs finaux.
	</p>
<p> <a href="#">Download zip file</a> </p>
      <h2>Explore between our last proposals</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default HowtoPage
